// material theme

@use '@angular/material' as mat;
@import 'ngx-toastr/toastr';
@import "variables";

@include mat.core();

// Define custom colors
$custom-body-color: #ebebeb;  // Background color
  // Text color

body {
  background-color: $custom-body-color;
  // color: white;
}

// You can also define styles for specific elements or classes using these colors
// .custom-element {
//   background-color: $custom-body-color;
//   color: $custom-text-color;
// }

// .another-element {
//   background-color: #FFB7B7;  // Another custom background color
//   color: #F4EEEE;            // Another custom text color
// }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}
